import request from '../utils/request'

export default {
    gf_cx_page(data){
        return request({
            url: 'api/fpgl/gf_cx_page',
            method:'post',
            data
        })
    },
    gf_insert(data){
        return request({
            url: 'api/fpgl/gf_insert',
            method:'post',
            data
        })
    },
    gf_delete(data){
        return request({
            url: 'api/fpgl/gf_delete',
            method:'post',
            data
        })
    },
    gf_update(data){
        return request({
            url: 'api/fpgl/gf_update',
            method:'post',
            data
        })
    },
}