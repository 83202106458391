<template>
    <div class="main">
        <div class="header">
            <van-search
                    v-model="searchValue"
                    :show-action="state.ifbtn"
                    shape="round"
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
            >
                <template #action v-if="state.ifbtn">
                    <div @click="addAction">添加</div>
                </template>
            </van-search>
        </div>
        <div class="list" v-if="!errstate">
            <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">
                <van-list
                        v-model:loading="state.loading"
                        :finished="state.finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-cell v-for="item in state.list" :key="item" :title="item.bz" @click="cellClick(item)">
                        <!-- 使用 title 插槽来自定义标题 -->
                        <template #title>
                            <div class="cellItem">
                                <div class="cell-message">
                                    <div>{{item.gfmc}}</div>
                                    <div><span class="item-icon">税号</span>{{item.nsrsbh}}</div>
                                </div>
                                <div class="btn-action" v-if="state.ifbtn">
                                    <!-- <van-button class="item-btn" type="primary" size="small">设为默认</van-button> -->
                                    <van-button class="item-btn" type="primary" size="small" @click.stop="deleteAction(item)">删除
                                    </van-button>
                                </div>
                            </div>
                        </template>
                    </van-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <van-empty image="error" v-if="errstate" :description=description />
    </div>
</template>

<script>
    import {defineComponent, ref, reactive, getCurrentInstance} from 'vue'
    import customRequest from '@/api/custom.js'
    import config from "@/config/"
    import {Dialog, Notify} from 'vant';

    export default defineComponent({
        name: "custom",
        setup() {
            const {proxy} = getCurrentInstance();
            const searchValue = ref("");
            const errstate = ref(false);
            const page = ref("1");
            const description = ref("");
            const pageSize = ref(config.request.pageSize);
            const state = reactive({
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                ifbtn :true
            });
            if (proxy.$root.$route.query.method === "select"){
                state.ifbtn=false;
            }else{
                state.ifbtn=true;
            }
            const searchMethod = (data) => {
                customRequest.gf_cx_page(data).then(res => {
                    if (res.returnCode === "00") {
                        errstate.value = false;
                        if (state.refreshing) {
                            state.list = [];
                            state.refreshing = false;
                            page.value = "1";
                        }
                        state.list.push(...res.rows.datas);
                        state.loading = false;
                        page.value = Number(page.value) + 1;
                        if (state.list.length >= res.rows.total) {
                            state.finished = true;
                            console.log("11111111111");
                        }
                    } else {
                        errstate.value = true;
                        description.value = res.returnMessage;
                    }
                });
            };
            const onSearch = () => {
                page.value = "1";
                state.list = [];
                searchMethod({
                    gjz: searchValue.value,
                    page: "1",
                    pageSize: pageSize.value
                });
            };
            const onLoad = () => {
                searchMethod({
                    gjz: searchValue.value,
                    page: page.value,
                    pageSize: pageSize.value
                });
            };
            const onRefresh = () => {
                // 清空列表数据
                state.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                state.loading = true;
                searchMethod({
                    gjz: searchValue.value,
                    page: "1",
                    pageSize: pageSize.value
                });
            };
            const addAction = () => {
                proxy.$root.$router.push({path: "/addCustom"})
            };
            const deleteAction = (item) => {
                Dialog.confirm({
                    title: config.commonMes.alertTitle,
                    message: '是否删除该客户信息？',
                }).then(() => {
                    customRequest.gf_delete({id: item.id}).then(res =>{
                        if (res.returnCode === "00"){
                            Notify({ type: 'success', message: res.returnMessage });
                        }else {
                            Notify({ type: 'danger', message: res.returnMessage });
                        }
                        state.list.splice(state.list.indexOf(item), 1);
                    });
                    })
                    .catch(() => {
                    });

            };
            const cellClick = (item)=>{
                if (proxy.$root.$route.query.method === "select"){
                    sessionStorage.removeItem("fpkj-sdkp-fptt");
                    sessionStorage.setItem("fpkj-sdkp-fptt",JSON.stringify(item));
                     proxy.$root.$router.go(-1);
                    // proxy.$root.$router.replace({name: "invoicing", params: {item: JSON.stringify(item)},meta: {keepAlive: true }});
                } else {
                    proxy.$root.$router.push({name: "addCustom", params:{item: JSON.stringify(item)},});
                }
            }
            return {
                errstate,
                description,
                searchValue,
                page,
                pageSize,
                state,
                onSearch,
                onLoad,
                onRefresh,
                addAction,
                deleteAction,
                cellClick
            }
        }
    });
</script>

<style lang="less" scoped>
    .cellItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .item-icon {
            background: #FB8357;
            padding: 3px;
            color: white;
            border-radius: 3px;
            margin-right: 10px
        }
        .btn-action {
            /*flex 布局*/
            display: flex;
            /*实现垂直居中*/
            align-items: center;
            /*实现水平居中*/
            justify-content: center;
            .item-btn {
                margin: 5px;
            }
        }
    }
    .header{
        margin-top: 10px;
    }

</style>